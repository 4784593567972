<template>
  <div>
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-form
        novalidate
        @submit.prevent="onSubmit"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="name"
                :name="$t('labels.name')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.name')"
                >
                  <b-form-input
                    v-model="form.name"
                    :placeholder="$t('labels.name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="email"
                :name="$t('labels.email')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.email')"
                >
                  <b-form-input
                    v-model="form.email"
                    :placeholder="$t('labels.email')"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="mobile"
                :name="$t('labels.mobile')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.mobile')"
                >
                  <b-form-input
                    v-model="form.mobile"
                    :placeholder="$t('labels.mobile')"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="password"
                :name="$t('labels.password')"
                :rules="isEdit ? '':'required'"
              >
                <b-form-group
                  :label="$t('labels.password')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :placeholder="$t('labels.password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="confirm_password"
                :name="$t('labels.confirm_password')"
                :rules="isEdit ? '':'required'"
              >
                <b-form-group
                  :label="$t('labels.confirm_password')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password_confirmation"
                      v-model="form.password_confirmation"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldTypeConfirm"
                      :placeholder="$t('labels.confirm_password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIconConfirm"
                        @click="passwordFieldTypeConfirm = (passwordFieldTypeConfirm === 'text') ? 'password' : 'text'"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row v-if="isEdit">
            <b-col
              cols="12"
              md="6"
            >
              <DatePicker
                v-model="form.blocking_start_date"
                :label="$t('blocking_start_date')"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <DatePicker
                :min="min"
                :max="max"
                v-model="form.blocking_end_date"
                :label="$t('blocking_end_date')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col cols="6">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>
            <b-col
              v-if="isEdit"
              cols="6"
            >
              <b-card-text class="mb-0">
                {{ $t('labels.blocking_status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.blocking_status"
                checked="true"
                value="true"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>
            <b-col
              cols="12 text-right"
              class="mt-4"
            >
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
                @click="reset()"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [togglePasswordVisibility, formMixin],
  data() {
    return {
      image: null,
      passwordFieldTypeConfirm: 'password',
      min: null,
      max: null,
      form: {
        name: null,
        email: null,
        mobile: null,
        image: null,
        password: null,
        status: 'active',
        password_confirmation: null,
        blocking_start_date: null,
        blocking_end_date: null,
        blocking_status: 'false',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.blocking_start_date': function (val) {
      if (val) {
        const maxDate = new Date(val)
        maxDate.setFullYear(maxDate.getFullYear() + 1)
        maxDate.setMonth(maxDate.getMonth())
        this.max = maxDate
        this.min = val
      }
    },
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    reset() {
      this.image = null
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            ...this.form,
            password: data.password ? data.password : null,
            password_confirmation: data.password_confirmation ? data.password : null,
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            status: data.status,
            blocking_start_date: data.blocking_start_date,
            blocking_end_date: data.blocking_end_date,
            blocking_status: data.blocking_status,
          }
          this.image = data.image_path
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>
<style>

</style>
